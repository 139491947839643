<script setup lang="ts">
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import {
	ALLOW_LOCATION_TITLE,
	ALLOW_LOCATION_DESCRIPTION,
	OKAY,
} from '@/locales/constants';
import locationIcon from '@/assets/icons/location.svg';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { CapacitorInMarket } from '@primeinsightsgroupllc-inmarket/capacitor-inmarket';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { TrackingEvents } from '@/enums';
import { useAppStore } from '@/stores/app';

const appStore = useAppStore();
const { userId } = storeToRefs(useUserStore());

const emit = defineEmits<{
	'close-modal': [];
}>();

const askPermission = async () => {
	const { location } = await CapacitorInMarket.requestPermissions();
	if (location === 'granted') {
		await CapacitorInMarket.setUserId({ userId: userId.value || '' });
		await CapacitorInMarket.startMonitoring();
		await appStore.trackEvent(TrackingEvents.MONITORING_STARTED);
	}
	emit('close-modal');
};
</script>
<template>
	<ModalCommonContent class="allow-location-modal">
		<template #header>
			{{ $t(ALLOW_LOCATION_TITLE) }}
		</template>
		<template #icon>
			<img
				:src="locationIcon"
				:alt="$t(ALLOW_LOCATION_TITLE)"
				class="location-img"
			/>
		</template>
		<template #default>
			<PrimeText tag="p" weight="600" size="lg">
				{{ $t(ALLOW_LOCATION_DESCRIPTION) }}
			</PrimeText>
		</template>
		<template #actions>
			<PrimeButton
				rounded="rounded"
				full-width
				:label="$t(OKAY)"
				@click="askPermission"
			/>
		</template>
	</ModalCommonContent>
</template>

<style scoped lang="scss">
.allow-location-modal :deep(.content-icon) {
	width: 12.5rem;
	height: 12.5rem;
}
</style>
