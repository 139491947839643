<script lang="ts" setup>
import { ref, watch } from 'vue';
import fallbackImage from '@/assets/icons/gift-duotone-thin.svg';
import { PrimeImageFallback } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const {
	src,
	transparent = false,
	rounded = false,
} = defineProps<{
	src: string | null;
	alt: string;
	transparent?: boolean;
	rounded?: boolean;
}>();

const logoUrl = ref(fallbackImage);

watch(
	() => src,
	(value) => {
		if (value) {
			logoUrl.value = value;
		}
	},
	{ immediate: true }
);
</script>

<template>
	<PrimeImageFallback
		class="reward-icon"
		:class="{ transparent, rounded }"
		:src="logoUrl"
		:fallback="fallbackImage"
		:alt="alt"
	/>
</template>

<style lang="scss" scoped>
@use '@/styles/mixins.scss' as *;
.reward-icon {
	width: 2.75rem;
	height: 1.75rem;
	flex: 0 0 auto;
	object-fit: contain;
	background-color: var(--white);
	border-radius: 0.5rem;

	&.rounded {
		width: 3.438rem;
		height: 1.875rem;
		border-radius: 0.5;
		overflow: hidden;
	}

	&.transparent {
		background-color: transparent;
	}

	@include breakpoint(mobile) {
		&.rounded {
			width: 4.625rem;
			height: 2.5rem;
		}
	}
}
</style>
