<script setup lang="ts">
import RewardImage from '@/components/common/RewardImage.vue';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { PiPercent } from '@primeinsightsgroupllc/prime-icons';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { MIN_COINS_VALUE } from '@/locales/constants';
import { localizePoints } from '@/utils/helpers';
import type { RewardsItem } from '@/types';

const {
	item,
	name = true,
	discount = true,
} = defineProps<{
	item: RewardsItem;
	name?: boolean;
	discount?: boolean;
}>();

const i18n = useI18n();

const minCoinsText = computed(() => {
	return i18n.t(MIN_COINS_VALUE, {
		value: localizePoints(item.min_coin_value),
	});
});
</script>

<template>
	<div class="rewards-item__header">
		<RewardImage
			:src="item.image_url"
			:alt="item.name"
			class="rewards-item__image"
		/>
		<div class="rewards-item__middle">
			<PrimeText v-if="name" size="sm" weight="600">
				{{ item.name }}
			</PrimeText>
			<PrimeText size="xs" color="grey-700">
				{{ minCoinsText }}
			</PrimeText>
		</div>
		<div
			v-if="item.has_discounted_options && discount"
			class="rewards-item__discount"
		>
			<PiPercent color="base-secondary" size="1.125rem" />
		</div>
	</div>
</template>

<style lang="scss" scoped>
@use '@/styles/mixins.scss' as *;

.rewards-item__header {
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.rewards-item__middle {
	display: flex;
	flex-direction: column;
	gap: 0.125rem;
}

.rewards-item__discount {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 1.875rem;
	height: 1.875rem;
	background-color: var(--base-secondary-200);
	border-radius: 50%;
}

.rewards-item__image {
	width: 4rem;
	height: 2.5rem;
}
</style>
