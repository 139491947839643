<script setup lang="ts">
import { computed } from 'vue';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import {
	REF_CARD_TITLE,
	REF_CARD_TEASER,
	REF_CARD_BUTTON,
} from '@/locales/constants';
import coins from '@/assets/icons/coins.svg';

const { layout = 'default' } = defineProps<{
	layout?: 'default' | 'long';
}>();

const { isMobile } = useScreenSize();

const teaserSize = computed(() =>
	isMobile.value || layout === 'default' ? 'xs' : 'sm'
);
</script>
<template>
	<div class="reffering-card" :class="layout">
		<img src="@/assets/galaxy.png" alt="galaxy" class="reffering-bg" />
		<img :src="coins" alt="coins icon" class="reffering-icon" />
		<div class="reffering-content">
			<PrimeText tag="h3" color="white" weight="600" class="reffering-title">
				{{ $t(REF_CARD_TITLE) }}
			</PrimeText>
			<PrimeText tag="p" :size="teaserSize" color="grey-100" weight="500">
				{{ $t(REF_CARD_TEASER) }}
			</PrimeText>
			<RouterLink
				v-if="layout !== 'long'"
				to="/referrals"
				class="reffering-link"
			>
				<PrimeText tag="span" size="sm" color="grey-100" weight="600" underline>
					{{ $t(REF_CARD_BUTTON) }}
				</PrimeText>
			</RouterLink>
		</div>
	</div>
</template>

<style scoped lang="scss">
@use '@/styles/mixins.scss' as *;
.reffering-card {
	position: relative;
	width: 100%;
	min-height: 15rem;
	padding: 1rem;
	background-color: var(--base-primary);
	border-radius: 0.75rem;
	border: 1px solid var(--base-primary);
	overflow: hidden;

	&.long {
		min-height: 8.875rem;
		padding: 1rem;
		display: flex;
		align-items: flex-start;
	}
	@include breakpoint(mobile) {
		&.long {
			padding: 1.25rem 1.5rem;
			align-items: center;
		}
	}
}
.reffering-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.reffering-icon {
	position: absolute;
	bottom: -0.25rem;
	right: -0.75rem;
	display: block;
	height: 5.594rem;
	width: auto;

	.long & {
		bottom: -0.188rem;
		right: -0.375rem;
	}

	@include breakpoint(mobile) {
		.long & {
			bottom: 0;
			right: 0;
		}
	}
}
.reffering-content {
	position: relative;

	.long & {
		max-width: 16.125rem;
	}

	@include breakpoint(mobile) {
		.long & {
			max-width: 23.25rem;
		}
	}
}
:deep(.p-text.reffering-title) {
	font-size: 1.375rem;
	line-height: 1.788rem;
	margin-bottom: 0.25rem;
}
.reffering-link {
	display: inline-block;
	margin-top: 2.125rem;
	padding: 0.375rem;
	border-radius: 0.5rem;
	transition: 0.4s;

	&:hover {
		background: rgba(255, 255, 255, 0.2);
	}
}
</style>
