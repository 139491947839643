import '@/styles/main.scss';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import i18n from './i18n';
import App from './App.vue';
import router from './router';
import PrimeVue from 'primevue/config';
import { getRootDomain } from './utils/helpers';
import { createGtm } from '@gtm-support/vue-gtm';
import { initSentry } from './utils/sentry';
import { useAppCookies } from './utils/composables/useAppCookies';
import { useMobileApp } from './utils/composables/useMobileApp';
import { modalsPinia } from '@primeinsightsgroupllc-modals/prime-modals';
import '@primeinsightsgroupllc-modals/prime-modals/dist/style.css';

console.log('MAIN.ts ENVS >>> ', import.meta.env);

const { marketingId } = useAppCookies();
const { isMobileApp } = useMobileApp();

const app = createApp(App);

i18n.setup();

initSentry(app);
app.use(i18n.vueI18n);
app.use(createPinia());
app.use(router);
app.use(PrimeVue);
app.use(modalsPinia);

if (marketingId) {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		customer: {
			id: marketingId,
		},
	});
}

app.use(
	createGtm({
		id: import.meta.env.VITE_GTM_CONTAINER_ID || 'GTM-UNDEFINED',
		enabled: true,
		debug: import.meta.env.MODE !== 'production',
		loadScript: true,
		vueRouter: router,
		ignoredViews: ['signature-login', 'reset-password'],
	})
);

getRootDomain();

app.mount('#app');

if (isMobileApp) {
	import('./mobile');
}
