<script setup lang="ts">
import { computed, ref } from 'vue';
import { COINS, SAVE_DISCOUNT } from '@/locales/constants';
import { localizePoints, transformCurrency } from '@/utils/helpers';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import {
	PrimeText,
	useRippleHoverEffect,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import type { RewardOption } from '@/types';

const { accentColor = 'base-primary' } = defineProps<{
	option: RewardOption;
	selected: boolean;
	accentColor?: 'base-primary' | 'text-default';
}>();

const emits = defineEmits<{
	(e: 'click'): void;
}>();

const { isMobile } = useScreenSize();
const { createRipple } = useRippleHoverEffect();

const buttonRef = ref<HTMLElement | null>(null);
const optionColor = computed(() => `var(--${accentColor})`);
const withoutDiscountOptionColor = computed(() =>
	accentColor === 'base-primary' ? 'var(--base-primary-500)' : 'var(--grey-500)'
);

const rewardOptionClick = (event: MouseEvent) => {
	emits('click');
	createRipple(buttonRef.value, event);
};
</script>

<template>
	<div :class="{ selected }">
		<div ref="buttonRef" class="reward-option" @click="rewardOptionClick">
			<div v-if="option.discount_percent" class="discount-tag">
				<PrimeText size="xs" weight="500" color="inherit">
					{{
						$t(SAVE_DISCOUNT, {
							percentage: `${option.discount_percent}%`,
						})
					}}
				</PrimeText>
			</div>
			<PrimeText
				:size="isMobile ? 'sm' : 'lg'"
				weight="500"
				class="option-money"
			>
				{{ transformCurrency(option.money_value, option.currency) }}
			</PrimeText>
			<template v-if="option.discount_percent">
				<PrimeText
					weight="500"
					color="grey-500"
					:size="isMobile ? 'xs' : 'sm'"
					class="option-coins without-discount"
				>
					{{ `${localizePoints(option.coin_value)} ${$t(COINS)}` }}
				</PrimeText>
				<PrimeText
					weight="500"
					color="grey-700"
					:size="isMobile ? 'xs' : 'sm'"
					class="option-coins"
				>
					{{
						`${localizePoints(option?.coin_value_discounted || 0)} ${$t(COINS)}`
					}}
				</PrimeText>
			</template>
			<PrimeText
				v-else
				weight="500"
				color="grey-700"
				:size="isMobile ? 'xs' : 'sm'"
				class="option-coins"
			>
				{{ `${localizePoints(option.coin_value)} ${$t(COINS)}` }}
			</PrimeText>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@use '@/styles/mixins.scss' as *;

.reward-option {
	--option-color: v-bind(optionColor);
	min-height: 4.938rem;

	padding: 1.5rem 1rem;
	background-color: var(--white);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px solid var(--grey-300);
	box-shadow: var(--card-shadow);
	border-radius: 0.75rem;
	cursor: pointer;
	transition: border-color 0.3s;
	position: relative;

	@include breakpoint(mobile) {
		min-height: 6rem;
		padding: 1.5rem;
	}

	.discount-tag {
		position: absolute;
		top: 0.25rem;
		left: 0.25rem;
		padding: 0 0.25rem;
		background-color: var(--base-secondary-04);
		border-radius: 3.125rem;
		color: var(--base-secondary);

		@include breakpoint(mobile) {
			top: 0.5rem;
			left: 0.5rem;
		}
	}

	.option-money {
		margin: 0.125rem 0;
		line-height: 1;
	}

	.option-coins {
		line-height: 1.2;
		text-align: center;
		letter-spacing: -0.25px;

		&.without-discount {
			text-decoration: line-through;
		}
	}

	&:hover {
		border: 1px solid var(--option-color);
	}

	.selected & {
		padding: 1.4375rem;
		color: var(--option-color);
		border: 2px solid var(--option-color);

		.discount-tag {
			top: 0.25rem;
			left: 0.25rem;
		}

		& .option-coins:not(.without-discount) {
			color: var(--option-color);
		}

		& .without-discount {
			color: v-bind(withoutDiscountOptionColor);
		}

		& .option-money {
			color: var(--option-color);
		}
	}
}
</style>
