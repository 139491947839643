<script setup lang="ts">
import type { SelectedReward } from '@/types';
import { SELECTED_REWARD, COINS_REACHED } from '@/locales/constants';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import RewardImage from '@/components/common/RewardImage.vue';
import { localizePoints, transformCurrency } from '@/utils/helpers';
import {
	PrimeProgressbar,
	PrimeText,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';

const props = defineProps<{
	selectedReward: SelectedReward;
	collectedCoins: number;
}>();

const { isTablet } = useScreenSize();
</script>

<template>
	<div>
		<div class="progress-information">
			<RewardImage
				:src="selectedReward.image_url"
				:alt="selectedReward.name"
				rounded
			/>
			<div>
				<PrimeText tag="h4" size="xs" weight="500" color="grey-700">
					{{ $t(SELECTED_REWARD) }}
				</PrimeText>
				<div class="reward-description">
					<PrimeText size="sm" weight="700" class="reward-amount">
						{{
							transformCurrency(
								selectedReward.money_value,
								selectedReward.currency
							)
						}}
					</PrimeText>
					<PrimeText size="sm" weight="500">{{
						selectedReward.name
					}}</PrimeText>
				</div>
			</div>
		</div>
		<div class="progress-bar-wrapper">
			<PrimeText tag="div" size="xs" color="grey-600" class="target-coins">
				{{
					`${localizePoints(collectedCoins)} / ${localizePoints(
						selectedReward.coin_value
					)} ${$t(COINS_REACHED)}`
				}}
			</PrimeText>
			<PrimeProgressbar
				progress-bar-rounded
				:height="isTablet ? '0.313rem' : '0.625rem'"
				:value="props.collectedCoins"
				:max-value="props.selectedReward.coin_value"
				bg="primary-default-700"
			/>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@use '@/styles/mixins.scss' as *;

.progress-information {
	display: flex;
	align-items: center;

	:deep(.reward-icon) {
		margin-right: 0.75rem;
	}
}

.reward-description {
	display: flex;
	flex-wrap: wrap;

	margin-top: 0.125rem;
	.p-text {
		line-height: 1.188rem;
	}

	@include breakpoint(tablet) {
		.p-text {
			font-size: 1rem;
			line-height: 1.375rem;
		}
	}
}

.reward-amount {
	margin-right: 0.3125rem;
}

.progress-bar-wrapper {
	width: 100%;
	margin-top: 0.875rem;

	@include breakpoint(tablet) {
		margin-top: 1.5rem;
	}
}

.target-coins {
	display: flex;
	align-items: center;
	height: 1.25rem;
	margin-bottom: 0.375rem;
}
</style>
